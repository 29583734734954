import Qs from "qs";
import axios from "axios";

export const req = params => {
  if (!params) {
    return new Promise((resolve, reject) => {
      reject(new Error("请设置请求参数"));
    });
  }
  if (!params.url) {
    return new Promise((resolve, reject) => {
      reject(new Error("请设置请求url"));
    });
  }
  return new Promise((resolve, reject) => {
    if (!params.method) {
      params.method = "post";
    }
    const config = {
      url: params.url,
      method: params.method,
      headers: {
        "Content-Type":
          params.ContentType || "application/x-www-form-urlencoded"
      },
      responseType: params.responseType || "json",
      timeout: 30000
    };
    if (params.method === "post") {
      config.paramsSerializer = function(params) {
        return Qs.stringify(params, {
          arrayFormat: "brackets"
        });
      };
      config.transformRequest = [
        function(data) {
          return Qs.stringify(data, {
            arrayFormat: "brackets"
          });
        }
      ];
    }
    if (params.method.toLowerCase() === "get") {
      Object.assign(config, {
        params: Object.assign(params.data, {
          timestamp: Date.parse(new Date()) / 1000
        })
      });
    } else {
      Object.assign(config, {
        data: params.data
      });
    }
    axios(config)
      .then(response => {
        const { data, status } = response;
        if (parseInt(status) === 200) {
          const { code } = data;
          if (parseInt(code) === 0) {
            resolve(data);
          } else {
            reject(data);
          }
        } else {
          reject(response);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};
