<template>
  <div id="app">
    <my-header></my-header>
    <router-view class="page-container" :style="containerStyle" />
    <my-footer ref="myFooter" v-if="hasFooter"></my-footer>
  </div>
</template>

<script>
import MyHeader from "@/components/common/MyHeader.vue";
import MyFooter from "@/components/common/MyFooter.vue";

export default {
  components: { MyHeader, MyFooter },
  data() {
    return {
      hasFooter: false,
      containerStyle: {}
    };
  },
  watch: {
    $route() {
      if (["HomeIndex", "ProjectMore"].includes(this.$route.name)) {
        this.hasFooter = false;
      } else {
        this.hasFooter = true;
      }
    },
    hasFooter() {
      if (this.hasFooter) {
        this.$nextTick(() => {
          const footerHeight = this.$refs.myFooter.$el.offsetHeight;
          this.containerStyle = {
            minHeight: `calc(100vh - ${footerHeight}px)`
          };
        });
      }
    }
  }
};
</script>

<style lang="less">
#app {
  min-height: 100vh;
  background-color: #ffffff;
  .page-container {
    min-height: 100vh;
  }
}
</style>
