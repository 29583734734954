import "@/assets/css/common.less";
import "amfe-flexible";

import * as apis from "@/common/apis.js";
import * as net from "@/common/net.js";
import * as utils from "@/common/utils.js";
import "@/common/vant.js";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.prototype.$apis = apis;
Vue.prototype.$net = net;
Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
