import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeIndex",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "HomeIndex" */ "@/views/home/Index.vue")
  },

  // /about
  {
    path: "/about/profile",
    name: "AboutProfile",
    component: () =>
      import(/* webpackChunkName: "AboutProfile" */ "@/views/about/Profile.vue")
  },
  {
    path: "/about/course",
    name: "AboutCourse",
    component: () =>
      import(/* webpackChunkName: "AboutCourse" */ "@/views/about/Course.vue")
  },
  {
    path: "/about/concept",
    name: "AboutConcept",
    component: () =>
      import(/* webpackChunkName: "AboutConcept" */ "@/views/about/Concept.vue")
  },

  // /landmark
  {
    path: "/landmark/",
    name: "LandmarkIndex",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkIndex" */ "@/views/landmark/Index.vue"
      )
  },
  {
    path: "/landmark/cultural-tourism",
    name: "LandmarkCulturalTourism",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkCulturalTourism" */ "@/views/landmark/CulturalTourism.vue"
      )
  },
  {
    path: "/landmark/residence",
    name: "LandmarkResidence",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkResidence" */ "@/views/landmark/Residence.vue"
      )
  },
  {
    path: "/landmark/residence/project-more",
    name: "ProjectMore",
    component: () =>
      import(
        /* webpackChunkName: "ProjectMore" */ "@/views/landmark/ProjectMore.vue"
      )
  },
  {
    path: "/landmark/commercial",
    name: "LandmarkCommercial",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkCommercial" */ "@/views/landmark/Commercial.vue"
      )
  },
  {
    path: "/landmark/student-apartment",
    name: "LandmarkStudentApartment",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkStudentApartment" */ "@/views/landmark/StudentApartment.vue"
      )
  },
  {
    path: "/landmark/land",
    name: "LandmarkLand",
    component: () =>
      import(/* webpackChunkName: "LandmarkLand" */ "@/views/landmark/Land.vue")
  },
  {
    path: "/landmark/property-services",
    name: "LandmarkPropertyServices",
    component: () =>
      import(
        /* webpackChunkName: "LandmarkPropertyServices" */ "@/views/landmark/PropertyServices.vue"
      )
  },

  // /fund
  {
    path: "/fund/",
    name: "FundIndex",
    component: () =>
      import(/* webpackChunkName: "FundIndex" */ "@/views/fund/Index.vue")
  },
  // {
  //   path: "/fund/ireland",
  //   name: "FundIreland",
  //   component: () =>
  //     import(/* webpackChunkName: "FundIreland" */ "@/views/fund/Ireland.vue")
  // },
  {
    path: "/fund/turkiye",
    name: "FundTurkiye",
    component: () =>
      import("@/views/fund/Turkiye.vue")
  },
  {
    path: "/fund/cyprus",
    name: "FundCyprus",
    component: () =>
      import(/* webpackChunkName: "FundCyprus" */ "@/views/fund/Cyprus.vue")
  },
  {
    path: "/fund/portugal",
    name: "FundPortugal",
    component: () =>
      import(/* webpackChunkName: "FundPortugal" */ "@/views/fund/Portugal.vue")
  },
  {
    path: "/fund/greece",
    name: "FundGreece",
    component: () =>
      import("@/views/fund/Greece.vue")
  },
  {
    path: "/fund/united_states",
    name: "FundUnitedStatese",
    component: () =>
      import("@/views/fund/UnitedStates.vue")
  },

  // /project
  {
    path: "/project/:projectId",
    name: "ProjectDetail",
    component: () =>
      import(
        /* webpackChunkName: "ProjectDetail" */ "@/views/project/Index.vue"
      )
  },

  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/404.vue")
  },
  {
    path: "*",
    redirect: () => {
      window.location.href = "/404";
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

export default router;
