// 把多次encodeURIComponent结果decode到最终不变
export const parseEncodeURIComponent = (s = "") => {
  const decodeStr = decodeURIComponent(s);
  if (decodeStr === s) {
    return decodeStr;
  } else {
    return parseEncodeURIComponent(decodeStr);
  }
};
export const isObjectEmpty = obj => {
  if (!obj || typeof obj !== "object") {
    return true;
  }
  for (const key in obj) {
    return false;
  }
  return true;
};
export const loadSrc = (url, domId, type) => {
  if (typeof window === "undefined") {
    return new Promise((resolve, reject) => {
      reject(new Error("请在浏览器环境中执行此方法"));
    });
  }
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("请设置加载资源的url"));
      return false;
    }
    if (!domId) {
      reject(new Error("请设置加载资源的dom Id"));
      return false;
    }
    if (document.getElementById(domId)) {
      resolve();
    } else if (type === "css") {
      const cssLink = document.createElement("link");
      cssLink.id = domId;
      cssLink.type = "text/css";
      cssLink.rel = "stylesheet";
      cssLink.href = url;
      cssLink.onload = () => {
        resolve();
      };
      document.head.appendChild(cssLink);
    } else {
      const jsScript = document.createElement("script");
      jsScript.id = domId;
      jsScript.type = "text/javascript";
      jsScript.onload = () => {
        resolve();
      };
      jsScript.src = url;
      document.body.appendChild(jsScript);
    }
  });
};
// ahex 转 rgba
export const colorToRgba = color => {
  let rgba = "";
  color = color.substr(1);
  const colorLen = color.length;
  let aValue, rValue, gValue, bValue;
  aValue = "0xFF";
  if (colorLen === 6 || colorLen === 8) {
    if (colorLen === 8) {
      aValue = "0x" + color.substr(0, 2);
      color = color.substr(2);
    }
    rValue = "0x" + color.substr(0, 2);
    gValue = "0x" + color.substr(2, 2);
    bValue = "0x" + color.substr(4, 2);
    rgba =
      "rgba(" +
      parseInt(rValue) +
      "," +
      parseInt(gValue) +
      "," +
      parseInt(bValue) +
      "," +
      parseInt(aValue) / 255 +
      ")";
  }
  return rgba;
};
export const getJsRunEnv = () => {
  if (typeof window === "undefined") {
    return "node";
  } else {
    return "browser";
  }
};
export const countStringBytes = str => {
  let r = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    // Shift_JIS: 0x0 ～ 0x80, 0xa0 , 0xa1 ～ 0xdf , 0xfd ～ 0xff
    // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
    if (
      (c >= 0x0 && c < 0x81) ||
      // eslint-disable-next-line prettier/prettier
      c === 0xF8F0 ||
      // eslint-disable-next-line prettier/prettier
      (c >= 0xFF61 && c < 0xFFA0) ||
      // eslint-disable-next-line prettier/prettier
      (c >= 0xF8F1 && c < 0xF8F4)
    ) {
      r += 1;
    } else {
      r += 2;
    }
  }
  return r;
};
export const cstr = s => {
  /* 处理null、undefined或NaN字符串 */
  if (s) {
    return s.toString();
  } else {
    return "";
  }
};
// 格式化时间戳
export const timestampToTime = timestamp => {
  let date = null;
  if (timestamp.toString().length === 13) {
    date = new Date(timestamp);
  } else {
    date = new Date(timestamp * 1000);
  }
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  const h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  const m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  const s =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
};
// 格式化日期
export const timestampToDate = timestamp => {
  // 格式化时间戳，输出2018-2-2
  const date = new Date(timestamp);
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + " ";
  return Y + M + D;
};
export const formatTimeCountToStr = t => {
  /* 格式化时间戳差 */
  if (t > 0) {
    let h = Math.floor((t / 60 / 60) % 24);
    if (h < 10) {
      h = `0${h}`;
    }
    let m = Math.floor((t / 60) % 60);
    if (m < 10) {
      m = `0${m}`;
    }
    let s = Math.floor(t % 60);
    if (s < 10) {
      s = `0${s}`;
    }
    return `${h}:${m}:${s}`;
  }
  return "";
};
export const formatTimeToStr = t => {
  if (t) {
    t = parseInt(t);
    const time = new Date(t * 1000);
    const year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let date = time.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let hour = time.getHours();
    if (hour < 10) {
      hour = `0${hour}`;
    }
    let minute = time.getMinutes();
    if (minute < 10) {
      minute = `0${minute}`;
    }
    let second = time.getSeconds();
    if (second < 10) {
      second = `0${second}`;
    }
    return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
  }
  return "";
};
export const deepClone = json => {
  /* 深度克隆对象 */
  try {
    return JSON.parse(JSON.stringify(json));
  } catch (error) {
    return json;
  }
  // if (obj === null || typeof obj !== 'object') {
  //   return obj
  // }
  // let clone
  // if (obj instanceof Date) {
  //   clone = new Date()
  //   clone.setTime(obj.getTime())
  //   return clone
  // }
  // if (obj instanceof Array) {
  //   clone = []
  //   let i
  //   const n = obj.length
  //   for (i = 0; i < n; i++) {
  //     clone[i] = deepClone(obj[i])
  //   }
  //   return clone
  // }
  // if (obj instanceof Object) {
  //   clone = {}
  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) {
  //       clone[key] = deepClone(obj[key])
  //     }
  //   }
  //   return clone
  // }
  // throw new Error(`Unable to clone obj! Its type isn't supported.`)
};
export const money = (m, c, w) => {
  /* m:金额;c:保留小数位数,只能取值0,1,2;w:1返回整个金额,2返回整数部分,3返回小数部分 */
  if (c !== 0 && c !== 1 && c !== 2) {
    // console.log('传入保留小数位数参数错误')
    return "";
  }
  if (w !== 1 && w !== 2 && w !== 3) {
    // console.log('传入返回类型参数错误')
    return "";
  }

  if (m === 0) {
    /* 由于0=false,需特殊处理 */
    return deal("0", "");
  } else {
    if (!m || isNaN(m)) {
      return "";
    }
    m = m.toString();
    if (!m.includes(".")) {
      return deal(m, "");
    } else {
      const sArr = m.split(".");
      return deal(sArr[0], sArr[1]);
    }
  }

  function deal(p, f) {
    /* p为整数部分 */
    if (c === 0) {
      if (w === 1) {
        return p;
      } else if (w === 2) {
        return p;
      } else if (w === 3) {
        return "";
      }
    } else if (c === 1) {
      if (f.length === 0) {
        f = "0";
      } else if (f.length >= 2) {
        f = f.substring(0, 1);
      }
      if (w === 1) {
        return p + "." + f;
      } else if (w === 2) {
        return p;
      } else if (w === 3) {
        return f;
      }
    } else if (c === 2) {
      if (f.length === 0) {
        f = "00";
      } else if (f.length === 1) {
        f = f + "0";
      } else if (f.length >= 3) {
        f = f.substring(0, 2);
      }
      if (w === 1) {
        return p + "." + f;
      } else if (w === 2) {
        return p;
      } else if (w === 3) {
        return f;
      }
    }
  }
};
export const myValidator = {
  /* 校验字段合法性rule */
  checkMobile: s => {
    /* 校验手机号格式是否正确 */
    if (s) {
      s = s.toString().trim();
      const rule = /^\d{3,20}$/;
      return rule.test(s);
    }
    return false;
  },
  checkEmail: s => {
    /* 校验邮箱格式是否正确 */
    if (s) {
      s = s.toString().trim();
      // eslint-disable-next-line
      const rule = /^(([^()[\]\\.,;:\s@\"]+(\.[^()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      return rule.test(s);
    }
    return false;
  },
  checkPwd: s => {
    /* 校验登录密码格式是否正确 */
    if (s) {
      s = s.toString().trim();
      const rule = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]{8,16}$/;
      return rule.test(s);
    }
    return false;
  },
  checkCode: s => {
    /* 校验短信或邮箱验证码格式是否正确 */
    if (s) {
      s = s.toString().trim();
      const rule = /^\d{6}$/;
      return rule.test(s);
    }
    return false;
  },
  checkImgCode: s => {
    /* 校验图片验证码格式是否正确 */
    if (s) {
      s = s.toString().trim();
      const rule = /^[a-zA-Z]{4}$/;
      return rule.test(s);
    }
    return false;
  },
  checkNickname: s => {
    /* 校验昵称格式是否正确 */
    if (s) {
      s = s.toString().trim();
      const rule = /^[A-Za-z0-9\u4E00-\u9FA5]{2,20}$/;
      return rule.test(s);
    }
    return false;
  }
};
/* 判断QQ */
export const checkIsQQ = () => {
  const ua = window.navigator.userAgent;
  if (ua.match(/QQ\/[0-9]/i)) {
    return true;
  } else {
    return false;
  }
};
/* 判断微信 */
export const checkIsWeixin = () => {
  const ua = window.navigator.userAgent;
  if (ua.match(/MicroMessenger\/[0-9]/i)) {
    return true;
  } else {
    return false;
  }
};
/* 判断小程序 */
export const checkIsWxApp = () => {
  const ua = window.navigator.userAgent;
  if (ua.match(/miniProgram/i) || window.__wxjs_environment === "miniprogram") {
    return true;
  } else {
    return false;
  }
};
export const getUserAgent = {
  browser(userAgent) {
    const u = userAgent;
    return {
      trident: u.includes("Trident"), // IE内核
      presto: u.includes("Presto"), // opera内核
      webKit: u.includes("AppleWebKit"), // 苹果、谷歌内核
      gecko: u.includes("Gecko") && !u.includes("KHTML"), // 火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
      android: /android.+version\/([\w\\.]+)\s+(?:mobile\s?safari|safari)*/i.test(
        u
      ), // android终端
      iPhone: u.includes("iPhone"), // 是否为iPhone或者QQHD浏览器
      iPad: u.includes("iPad"), // 是否iPad
      webApp: !u.includes("Safari"), // Safari & Safari Mobile
      weixin: u.includes("MicroMessenger"), // 是否微信
      qq: /(QQ)\/([\d\\.]+)/i.test(u), // 是否QQ
      wxapp: u.includes("miniProgram"), // 是否微信小程序
      bdbrowser: u.includes("BIDUBrowser"), // 是否百度浏览器
      qqbrowser: u.includes("qqbrowser"), // 是否qq浏览器  qqbrowser, qqbrowserlite
      uc: /((?:[\s\\/])uc?\s?browser|(?:juc.+)ucweb)[\\/\s]?([\w\\.]+)/i.test(u) // 是否uc浏览器
    };
  }
};
/**
 * 函数防抖(只执行最后一次点击)
 */
export const debounce = (fn, delay) => {
  let timer = null;
  delay = delay || 300;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};
/**
 * 函数节流
 */
export const throttle = (fn, delay) => {
  let last = Date.now();
  delay = delay || 300;
  return (...args) => {
    const now = Date.now();
    if (now - last >= delay) {
      last = now;
      fn.apply(this, args);
    }
  };
};
