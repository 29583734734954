<template>
  <section class="my-header">
    <ul class="header-wrap">
      <li class="l" @click="handleShowMenu">
        <img src="//static.hoolihome.com/global-jwp/assets/m/header-menu.png" />
      </li>
      <li class="c" @click="handleGoNext('/')">
        <img src="//static.hoolihome.com/global-jwp/assets/m/header-logo.png" />
      </li>
      <li class="r"></li>
      <!-- <li class="r" @click="handleToggleLanguage">
        <p class="lang">中</p>
      </li> -->
    </ul>
    <van-popup
      v-model="isShowMenu"
      closeable
      position="left"
      get-container="body"
      class="menu-popup"
    >
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item
          :class="{ active: activeName === 'home' }"
          :is-link="false"
          :border="false"
          :disabled="true"
          name="home"
          @click.native="handleGoNext('/')"
        >
          <template #title>
            <p class="en">Homepage</p>
          </template>
        </van-collapse-item>
        <van-collapse-item
          :class="{ active: activeName === 'about' }"
          :is-link="false"
          :border="false"
          name="about"
        >
          <template #title>
            <p class="en">About JWP</p>
          </template>
          <ul class="list">
            <li class="item" @click="handleGoNext('/about/profile')">
              Enterprise Profile
            </li>
            <li class="item" @click="handleGoNext('/about/course')">
              Landmark Developments
            </li>
            <li class="item" @click="handleGoNext('/about/concept')">
              Cultural Concept
            </li>
          </ul>
        </van-collapse-item>
        <van-collapse-item
          :class="{ active: activeName === 'developer' }"
          :is-link="false"
          :border="false"
          name="developer"
        >
          <template #title>
            <p class="en">Real Estate</p>
          </template>
          <ul class="list">
            <li class="item" @click="handleGoNext('/landmark')">
              Real Estate Overview
            </li>
            <li
              class="item"
              @click="handleGoNext('/landmark/cultural-tourism')"
            >
              Cultural Tourism
            </li>
            <li class="item" @click="handleGoNext('/landmark/residence')">
              Residential Housing
            </li>
            <li class="item" @click="handleGoNext('/landmark/commercial')">
              Nursing Home
            </li>
            <li
              class="item"
              @click="handleGoNext('/landmark/student-apartment')"
            >
              Student Apartment
            </li>
            <li class="item" @click="handleGoNext('/landmark/land')">
              Land Reserve
            </li>
            <li
              class="item"
              @click="handleGoNext('/landmark/property-services')"
            >
              Property Services
            </li>
          </ul>
        </van-collapse-item>
        <van-collapse-item
          :class="{ active: activeName === 'fund' }"
          :is-link="false"
          :border="false"
          name="fund"
        >
          <template #title>
            <p class="en">Fund Sector</p>
          </template>
          <ul class="list">
            <li class="item" @click="handleGoNext('/fund')">Fund Overview</li>
            <!-- <li class="item" @click="handleGoNext('/fund/ireland')">
              Fund in Ireland
            </li> -->
            <li class="item" @click="handleGoNext('/fund/cyprus')">
              Fund in Cyprus
            </li>
            <li class="item" @click="handleGoNext('/fund/turkiye')">
              Fund in Turkiye
            </li>
            <li class="item" @click="handleGoNext('/fund/portugal')">
              Fund in Portugal
            </li>
            <li class="item" @click="handleGoNext('/fund/greece')">
              Fund in Greece
            </li>
            <li class="item" @click="handleGoNext('/fund/united_states')">
              Fund in U.S
            </li>
          </ul>
        </van-collapse-item>
      </van-collapse>
    </van-popup>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isShowMenu: false,
      activeName: "home",
    };
  },
  mounted() {
    const p = window.location.pathname;
    if (p.includes("/about")) {
      this.activeName = "about";
    } else if (p.includes("/landmark")) {
      this.activeName = "developer";
    } else if (p.includes("/fund")) {
      this.activeName = "fund";
    } else {
      this.activeName = "home";
    }
  },
  methods: {
    handleShowMenu() {
      this.isShowMenu = true;
    },
    handleToggleLanguage() {
      window.location.href = `/cn${this.$route.fullPath}`;
    },
    handleGoNext(path) {
      window.location.href = path;
    },
  },
};
</script>

<style lang="less">
.menu-popup {
  width: 100vw;
  height: 100vh;
  background-color: #2d2d2d;
  .van-collapse {
    &::after {
      display: none;
    }
    .van-collapse-item {
      &::after {
        content: " ";
        position: absolute;
        left: 22px;
        right: 22px;
        bottom: 0;
        border-bottom: 1px #fff solid;
        transform: scaleY(0.5);
      }
      &.active {
        .van-collapse-item__title {
          &.van-collapse-item__title--disabled {
            &::before {
              transform: translateY(-50%);
            }
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 14px;
            height: 22px;
            background: rgba(255, 255, 255, 1);
          }
        }
      }
      .van-cell {
        padding: 22px;
        background-color: #2d2d2d;
        &.van-collapse-item__title {
          padding-left: 41px;
        }
        &.van-collapse-item__title--expanded {
          height: auto;
          &:not(.van-collapse-item__title--disabled) {
            padding-bottom: 0;
          }
        }
        .van-cell__title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .en {
            font-size: 18px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .van-collapse-item__content {
        padding: 0 41px;
        background-color: #2d2d2d;
        .list {
          padding-bottom: 24px;
          .item {
            padding: 14px 0;
            font-size: 15px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            &:first-child {
              padding-top: 24px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.my-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.7);
  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .l {
      padding: 10px 22px;
      &:active {
        opacity: 0.9;
      }
      img {
        width: 14px;
      }
    }
    .c {
      img {
        width: 40px;
      }
    }
    .r {
      padding: 10px 22px;
      &:active {
        opacity: 0.9;
      }
      .lang {
        font-size: 13px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
