import Vue from "vue";
import {
  Image,
  Lazyload,
  Toast,
  Dialog,
  Popup,
  Collapse,
  CollapseItem
} from "vant";

Vue.use(Image)
  .use(Lazyload, { lazyComponent: true })
  .use(Toast)
  .use(Dialog)
  .use(Popup)
  .use(Collapse)
  .use(CollapseItem);

Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
