const protocol = "//"; // http、https，同源策略

const api = () => {
  let api = "";
  switch (process.env.VUE_APP_ENV) {
    case "prod": {
      api = "thailand.jwpworld.com";
      break;
    }
    default: {
      api = "thailand.jwpworld.com";
      break;
    }
  }
  return protocol + api;
};

export const Api = api();
